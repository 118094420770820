<template>
	<div class="wrap">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh;">
			<van-list v-model="loading" :finished="finished" @load="getData">
				<van-cell-group>
					<van-cell>
						<template #title>
							<span>{{ list[0] ? list[0].userName : "-" }}</span>
							&nbsp;
							<span>{{ formatTime("", "YYYY-MM-DD") }}</span>
							&nbsp;
							<span>{{ getDayString(getDayOfTheWeek()) }}</span>
						</template>
					</van-cell>

					<van-cell>
						<template #title>
							<Editor v-model="currentContent" />
						</template>
					</van-cell>

					<van-cell>
						<van-button type="primary" size="small" @click="newLogHandler">
							保存
						</van-button>
					</van-cell>
				</van-cell-group>

				<template v-if="list.length > 0">
					<van-cell-group v-for="(item, index) in list" :key="index">
						<van-cell>
							<template #title>
								<span>{{ item.userName }}</span>
								&nbsp;
								<span>{{ formatTime(item.logDate, "YYYY-MM-DD") }}</span>
								&nbsp;
								<span>{{ getDayString(getDayOfTheWeek(item.logDate)) }}</span>
							</template>
						</van-cell>

						<van-cell>
							<template #title>
								<Editor v-if="item.state" :show="item.state" v-model="item.logContent" />
								<div v-else v-html="item.logContent"></div>
							</template>
						</van-cell>

						<van-cell class="submitBtn">
							<div class="submitTitle">{{item.submitTime ? item.submitTime+"编辑" : ''}}</div>
							<van-button type="primary" v-if="item.logContent && item.isEditable" size="small"
								@click="handleClickEdit(item, index)">
								{{ item.state ? "保存" : "编辑" }}
							</van-button>
							<van-button type="primary" v-if="!item.logContent && item.isEditable && index !=0" size="small"
								@click="handleClickEdit(item, index)">
								{{ item.state ? "保存" : "补交" }}
							</van-button>
							
						</van-cell>
					</van-cell-group>
				</template>

				<div class="empty" v-if="list.length == 0">
					<van-empty description="没有内容，空空如也~"
						:image="require('@/assets/img/mag_pic_noinformation.svg')"></van-empty>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		formatTime,
		getDayOfTheWeek
	} from "@/utils/DateUtil";
	import {
		getUserLog,
		updateUserLog
	} from "@/api/user";
	import Editor from "./Editor.vue";

	const getDayString = (day) => {
		const days = {
			0: "星期天",
			1: "星期一",
			2: "星期二",
			3: "星期三",
			4: "星期四",
			5: "星期五",
			6: "星期六",
		};
		return days[day];
	};

	export default {
		data() {
			return {
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				size: 10,
				list: [],

				currentContent: "",
			};
		},
		components: {
			Editor
		},
		watch: {},
		created() {},
		methods: {
			async handleClickEdit(item, index) {
				this.list[index].state = !this.list[index].state;
				if (!this.list[index].state) {
					try {
						let edit = {
							id: item.id,
							userId: item.userId,
							logContent: item.logContent,
							logDate: item.logDate,
						};
						updateUserLog(edit);
						this.page = 1;
						this.list = [];
						await this.getData();
					} catch (error) {}
				}
			},

			async newLogHandler(item, index) {
				try {
					if(!this.currentContent){
						this.$toast("内容不能为空");
						return
					}
					let edit = {
						id: this.list[0].id,
						userId: this.list[0].userId,
						logContent: this.currentContent,
						logDate: this.list[0].logDate,
					};
					updateUserLog(edit);
					this.page = 1;
					this.list = [];
					this.getData();
					this.currentContent = "";
				} catch (error) {
					console.log("error -> :", error);
				}
			},

			getData() {
				try {
					let params = {
						pageNo: this.page,
						pageSize: this.size,
						userId: localStorage.getItem("userId"),
					};
					getUserLog(params).then((res) => {
						if (res.status == 200) {
							if (this.refreshing) {
								this.list = [];
								this.refreshing = false;
							}
							if (res.data && res.data.list.length > 0) {
								res.data.list = res.data.list.map((item) => {
									item.state = false;
									return item;
								});
								console.log('--------page',this.page)
								this.list = this.list.concat(res.data.list);
								this.page += 1;
							}
							this.loading = false;
							if (this.list.length >= res.data.total) {
								this.finished = true;
							}
						}
					});
				} catch (e) {
					this.loading = false;
					this.finished = true;
				}
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				this.loading = true;
				this.page = 1;
				// 重新加载数据
				this.getData();
			},
			formatTime,
			getDayOfTheWeek,
			getDayString,
		},
		computed: {
			// 从哪个页面跳转过来的
			isHasNav() {
				return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
			},
		},
	};
</script>

<style lang="less" scoped>
	.submitBtn {
		/deep/.van-cell__value {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.submitTitle{
			margin-left:0.4rem;
			color: #ccc;
		}
	}

	.wrap {
		padding: 0 10px;

		.title {
			display: flex;
			align-items: center;

			.line {
				width: 0.2rem;
				height: 0.8rem;
				background: #037ae7;
				margin-right: 10px;
			}
		}

		/deep/ .blue .van-cell__value {
			color: #037ae7;
		}
		/deep/.van-cell__value--alone{
			text-align: right;
		}

		/deep/ .red .van-cell__value {
			color: rgb(255, 77, 79);
		}

		/deep/ .van-cell-group {
			margin: 12px 0;
		}
	}
</style>