<template>
  <div v-if="show">
    <div ref="editor"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  watch: {
    value(val) {
      if (val === "") {
        this.quill.setText("");
      }
    },
  },
  data() {
    return {
      quill: null,
    };
  },
  methods: {},
  mounted() {
    this.quill = new Quill(this.$refs.editor, {
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          // ["blockquote", "code-block"],
          // [{ header: 1 }, { header: 2 }],
          // [{ list: "ordered" }, { list: "bullet" }],
          // [{ script: "sub" }, { script: "super" }],
          // [{ indent: "-1" }, { indent: "+1" }],
          // [{ direction: "rtl" }],
          // [{ size: ["small", false, "large", "huge"] }],
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // [{ color: [] }, { background: [] }],
          // [{ font: [] }],
          // [{ align: [] }],
          // ["clean"],
          // ["link", "image", "video"],
        ],
      },
    });

    this.$emit("getQuill", this.quill);

    if (this.value) {
      this.quill.pasteHTML(this.value);
    }

    this.quill.on("text-change", (delta, oldDelta, source) => {
      this.$emit("input", this.quill.root.innerHTML);
    });

    setTimeout(() => {
      this.$refs.editor && this.$refs.editor.focus(true);
    }, 1000);
  },
  created() {},
};
</script>

<style lang="less" scoped>
/deep/ .ql-editor {
  height: 20vh;
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
}
</style>
